import React, { useEffect, useState } from "react";
import { Badge, Button, Modal, Form, FormControl } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
const arr = [{ label: "Name", value: "Name" }];

const ViewAllIpList = () => {
    const { user } = useAuth();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    merchantSecret: "",
    ip_address: "",
  });
  const ViewAllMerchant = async () => {
    console.log(ViewAllMerchant, "shee");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: user.merchantId,
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://cubexis.in/api/getAllMerchantIpList.php`,
        requestOptions
      );
      const data = await res.json();
      console.log("Merchant", data);
      if (data.status === "00") {
        setData(data.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const handleOpenIP = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const addIP = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        merchantId: user.merchantId,
        merchantSecret: user.merchantSecret,
        ip_address: formData.ip_address,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://cubexis.in/api/addMerchantIpAddress.php",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status === "00") {
        Swal.fire({
          icon: "success",
          title: "Add Successfully",
          text: "Webhook added successfully!",
        });
        ViewAllMerchant();
        handleCloseModal();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };
  useEffect(() => {
    ViewAllMerchant();
  }, []);

  return (
    <>
      <div className="p-2 ">
        <div className="row m-3">
          <h5 className="col">View IP Merchant</h5>
          <div className=" col  d-flex justify-content-end">
            <Button onClick={handleOpenIP}>Add IP</Button>
          </div>
        </div>
        <div
          className=" bg-white "
          style={{ border: "1px solid rgba(0,0,0,1)", borderRadius: "15px" }}
        >
          <table className="table" style={{ fontFamily: "poppins" }}>
            <thead>
              <tr>
                <th
                  style={{ borderRadius: "15px 0px 0px 0px" }}
                  className=" text-white text-center py-3 bg-success"
                >
                  User Id
                </th>

                <th className="bg-success text-white text-center py-3"> ID</th>
                <th className="bg-success text-white text-center py-3">
                  Client Name
                </th>
                <th className="bg-success text-white text-center py-3 ">
                  IP Address
                </th>
                <th className="bg-success text-white text-center py-3 ">
                  IP Status
                </th>
                <th className="bg-success text-white text-center py-3">
                  {" "}
                  Valid from
                </th>

                <th
                  style={{ borderRadius: "0px 15px 0px 0px" }}
                  className="bg-success text-center py-3 text-white text-center py-3"
                >
                  valid To
                </th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : (
                data.map((item) => (
                  <tr key={item.UserName}>
                    <td className="text-center align-middle">
                      {item.user_id || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.id || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.client_name || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.ip_address || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.ip_status || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.valid_from
                        ? new Date(item.valid_from).toLocaleDateString()
                        : "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.valid_to
                        ? new Date(item.valid_to).toLocaleDateString()
                        : "NA"}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Webhook</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="col" controlId="ip_address">
              <Form.Label>IP Address</Form.Label>
              <FormControl
                type="text"
                placeholder="0.0.0.0"
                value={formData.ip_address}
                onChange={(e) =>
                  setFormData({ ...formData, ip_address: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={addIP}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewAllIpList;